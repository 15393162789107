.features {
    text-align: center;
    /* Centralize the text of all elements */
    padding: 40px 0;
    /* Add vertical padding for better spacing */
}

.features h2 {
    margin-bottom: 30px;
    font-size: 2rem;
    /* Increase size for better prominence */
}

.feature-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    /* Space between feature items */
    transition: transform 0.3s ease;
    /* Prepare for a smooth hover effect */
}

.feature-item i {
    font-size: 50px;
    /* Enlarge the icons */
    margin-bottom: 10px;
    color: #3498db;
    /* Change this to any desired color */
}

.feature-item b {
    font-size: 1.5rem;
    /* Increase size for better visibility */
    margin-bottom: 10px;
}

.feature-item:hover {
    transform: translateY(-10px);
    /* Move the item up slightly on hover */
}