footer {
    background-color: #1a1a1a;
    /* Dark gray */
    color: #f6f6f6;
    /* Light gray */
    padding: 20px 0;
    font-size: 0.9rem;
}

.footer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.footer-links,
.social-media-links,
.copyright {
    flex: 1;
    text-align: center;
    margin: 10px 0;
}

.footer-links h4,
.social-media-links h4 {
    margin-bottom: 15px;
    font-weight: 600;
}

.footer-links a,
.social-media-links a {
    margin: 0 10px;
    color: #f6f6f6;
    transition: color 0.2s ease;
}

.footer-links a:hover,
.social-media-links a:hover {
    color: #3498db;
    /* Light blue for hover effect */
}

.social-media-links i {
    font-size: 1.5rem;
    /* Bigger icons for better visibility */
}