.hero {
    position: relative;
    height: 60vh;
    overflow: hidden;

    /* Flexbox settings for vertical centering */
    display: flex;
    justify-content: center;
    /* center horizontally */
    align-items: center;
    /* center vertically */
}

.hero-content {
    position: relative;
    z-index: 2;
    padding: 20px;
    text-align: center;
    color: white;

    width: 80%;
    max-width: 1200px;
    /* No need for margin: 0 auto; because of Flexbox */
}

.hero-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* This will cover the container without distorting the video */
    z-index: 1;
}


.hero::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(circle, transparent, rgba(0, 0, 0, 0.6));
    z-index: 1;
    /* between video and content */
}

.hero-content {
    /* position: relative; */
    z-index: 2;
    /* ensure content is above the video */
    /* Style this further as needed to adjust positioning, text color, etc. */
}

.hero-content h1,
.hero-content p,
.hero-content a {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}