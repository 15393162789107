body {
  background-color: #121212;
  color: #FFFFFF;
  font-family: 'Arial', sans-serif;
  /* Or any other font you'd like */
}

a,
.btn-primary {
  color: #1E88E5;
  transition: color 0.3s ease;
}

a:hover,
.btn-primary:hover {
  color: #0A74C1;
  /* A slightly darker blue for hover effect */
}

.text-secondary {
  color: #757575;
}

.alert,
.btn-danger {
  color: #D32F2F;
}

/* You might also want to adjust your other components slightly to fit with the dark theme. */

.features i,
.how-it-works i {
  color: #1E88E5;
  /* Giving the icons in these sections the primary blue color */
}

.container {
  width: 80%;
  margin: auto;
  overflow: hidden;
}

button {
  padding: 10px 20px;
  background-color: #007BFF;
  /* Vibrant blue for CTAs */
  border: none;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

img {
  max-width: 100%;
}

html {
  scroll-behavior: smooth;
}