/* .how-it-works {
    text-align: center;
    padding: 40px 0;
}

.how-it-works h2 {
    margin-bottom: 30px;
    font-size: 2rem;
}

.steps-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.step {
    background-color: #f6f6f6;
    padding: 20px;
    flex: 1;
    margin: 10px;
    border-radius: 8px;
    transition: transform 0.3s ease;
}

.step:hover {
    transform: translateY(-10px);
}

.step i {
    font-size: 40px;
    margin-bottom: 10px;
    color: #3498db;
}

.step h3 {
    font-size: 1.4rem;
    margin-bottom: 10px;
}

*/
.how-it-works {
    background-color: #1E1E1E;
    padding: 40px 20px;
    border-radius: 8px;
    flex-direction: column;
    /* Stack children vertically */
    align-items: center;
    /* Center children horizontally */
}

.how-it-works h2 {
    color: #FFFFFF;
    width: 100%;
    /* Take full width */
    text-align: center;
    /* Centered header */
    margin-bottom: 30px;
    font-size: 24px;
}

.steps-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.steps-container .step {
    flex: 1;
    display: flex;
    flex-direction: column;
    /* Stack children vertically */
    align-items: center;
    /* Center children horizontally */
    padding: 20px;
    border: 1px solid #2a2a2a;
    /* Optional: Slight border for definition */
    border-radius: 8px;
    margin: 0 10px;
    /* Slight margin for separation */
}

.steps-container .step-icon {
    background-color: #1E88E5;
    color: #FFFFFF;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    /* Space between icon and title */
    font-size: 20px;
}

.step h3 {
    color: #FFFFFF;
    margin-bottom: 10px;
}

.step p {
    color: #757575;
    text-align: center !important;
    /* Center the description */
    max-width: 80%;
    /* Ensure descriptions don't get too wide */
}

.step i {
    font-size: 40px;
    margin-bottom: 10px;
    color: #3498db;
}

.step:hover {
    transform: translateY(-10px);
}