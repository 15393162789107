.tokenomics {
    background-color: #1b262d;
    color: #ffffff;
    padding: 50px 0;
    text-align: center;
}


.distribution,
.trading-fees,
.game-fees {
    margin-bottom: 30px;
}

.color-box {
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-right: 5px;
}

.color-box.lp {
    background-color: #f39c12;
}

.color-box.marketing {
    background-color: #2980b9;
}

.color-box.jackpot {
    background-color: #2ecc71;
}

.chart-container {
    display: flex;
    justify-content: center;
    /* Horizontally center children */
    align-items: center;
    /* Vertically center children */
    gap: 20px;
    flex-wrap: wrap;
    /* Allow children to wrap to next line if needed */
    max-width: 100%;
}

.piechart {
    flex: 1;
    /* Make sure the PieCharts take as much space as available */
}

.fee-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* Centering children */
}

.fees-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    /* Allows wrapping on smaller screens */
    gap: 40px;
    /* Spacing between the two fee sections */
}

.fee-container {
    flex: 1;
    /* Allows each fee section to take up equal width */
    max-width: calc(50% - 20px);
    /* Limits the width, accounting for the gap */
}

@media screen and (max-width: 768px) {
    .fee-container {
        max-width: 100%;
        /* Stacks them on top of each other on smaller screens */
    }
}

/* Below lines make sure that on smaller screens the icons and text stack and center nicely */
@media screen and (max-width: 768px) {
    .chart-container {
        flex-direction: column;
    }

    .piechart,
    .legend {
        flex: none;
        /* They will take the space required by content, no more, no less */
        max-width: 100%;
        text-align: center;
        /* Center the text in case it wraps to the next line */
    }
}

.legend {
    flex: 1;
    /* This will take up the remaining space in the row */
    list-style-type: none;
    padding: 0;
}

/* For smaller screens, you can adjust the layout as desired. 
   Here's an example to stack the chart and legend on top of each other for screens smaller than 768px: */
@media screen and (max-width: 768px) {
    .chart-container {
        flex-direction: column;
    }

    .piechart,
    .legend {
        flex: none;
    }
}

.fees-section {
    padding: 50px 0;
    text-align: center;
    color: #f5f5f5;
    /* Or another color suitable to your theme */
}

.fees-section h2,
.fees-section h3 {
    margin-bottom: 20px;
    font-weight: bold;
}

.fees-section p {
    margin-bottom: 40px;
    font-size: 18px;
    opacity: 0.9;
}

.fee-container {
    margin-bottom: 40px;
}

.chart-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.chart-container .fa-icon {
    /* FontAwesome's icon class might differ */
    margin-right: 10px;
}

.conclusion {
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.1);
    /* Adds a shade to the background */
    border-radius: 10px;
}