.start-playing {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #8c2f1b;
    padding: 50px 0;
    border-top: 5px solid #5a1e10;
    border-bottom: 5px solid #5a1e10;
}

.start-playing h2 {
    font-size: 24px;
    margin-bottom: 20px;
}

.start-playing p {
    font-size: 18px;
    margin-bottom: 40px;
    opacity: 0.9;
}

.enter-arena-btn {
    padding: 12px 24px;
    background-color: #f39c12;
    /* your primary color or a contrasting one */
    color: #ffffff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: transform 0.3s;
}

.enter-arena-btn:hover {
    transform: scale(1.05);
    /* subtle zoom on hover */
}

.gladiator-helmet {
    width: 180px;
    /* Adjust based on the desired size */
    height: auto;
    margin-bottom: 40px;
    /* Provides spacing between the image and the button */
}